/**
 * @fileoverview This file encapsulates the GeneralButton function and methods used
 * by the underlying library.
 */

import styled from "styled-components";

/** Function holding the GeneralButton Component. */
export default function GeneralButton(props) {
  return <Button onClick={props.action}>{props.title}</Button>;
}

const Button = styled.button`
  min-width: 200px;
  min-height: 50px;
  text-align: center;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
  //   margin: 30px auto;
  background-color: #1a73e8;
  color: white;

  font-weight: 500;
  font-family: sans-serif;
  margin: 16px;
`;
