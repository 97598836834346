/**
 * @fileoverview This file encapsulates the Update function and methods used
 * by the underlying library.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import GeneralButton from "../components/GeneralButton";
import API from "../Util/Api";
import Loading from "../components/Loading";
import { SelectModal } from "../components/SelectModal";
import { StatusModal } from "../components/StatusModal";

/** Function holding the Update Component. */
function Update(props) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedMediationGroup, setSelectedMediationGroup] = useState(null);
  const [updateLines, setUpdateLines] = useState({});
  const [lines, setLines] = useState([]);
  const [groupModalShow, setGroupModalShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showStatus, setShowStatus] = useState(false);

  const navigate = useNavigate();

  /** Lists the medation groups on page load. */
  useEffect(() => {
    if (!props) {
      navigate("/");
      return;
    }

    const { account, selectedApp } = props;
    setSelectedApp(selectedApp);

    const listGroups = async () => {
      try {
        const res = await API.listMediationGroups(account, selectedApp.appId);
        if (res) {
          setGroups(res);
        } else {
          setGroups([]);
        }
      } catch {
        navigate("/");
      }
      setLoading(false);
    };

    listGroups();
  }, [props, navigate]);

  /** Sets the mediation group lines after a mediation group is selected. */
  useEffect(() => {
    if (
      selectedMediationGroup &&
      Object.keys(selectedMediationGroup).length > 2
    ) {
      const group = selectedMediationGroup;
      const lines = Object.values(group.mediationGroupLines)
        .filter((item) => {
          return item.adSourceId === "1215381445328257950";
        })
        .sort((a, b) => b.cpmMicros - a.cpmMicros);
      setLines(lines);
    }
  }, [selectedMediationGroup]);

  /**
   * Sets the mediation group lines to be updated.
   * @param {Object} item
   * @param {string} field
   * @param {*} value
   */
  const setUpdates = (item, field, value) => {
    const toSet = updateLines;
    if (toSet[item.id]) {
      if (value !== "") {
        toSet[item.id][field] = value;
      } else {
        delete toSet[item.id][field];
        if (Object.keys(toSet[item.id]).length === 1) {
          delete toSet[item.id];
        }
      }
    } else {
      if (value !== "") {
        toSet[item.id] = { [field]: value, item };
      }
    }
    setUpdateLines(toSet);
  };

  /**
   * Returns the existing Ad Unit Mappings associated with the specified mediation group.
   * @return {Array<Object>} mappings
   */
  const getMappings = async () => {
    const { account } = props;
    const adUnitIds = selectedMediationGroup.targeting.adUnitIds;

    const mappings = {};
    const linkedMappings = [];
    for (const id of adUnitIds) {
      mappings[id] = await API.listAdUnitMappings(
        account + `/adUnits/${id.split("/")[1]}`
      );
    }

    for (const line of Object.values(updateLines)) {
      const foundMappings = [];
      const connectedMappings = Object.values(line.item.adUnitMappings);

      for (const unit of Object.values(mappings)) {
        for (const listedMapping of unit) {
          if (connectedMappings.includes(listedMapping.name)) {
            foundMappings.push(listedMapping);
          }
        }
      }
      line.foundMappings = foundMappings;
      linkedMappings.push(line);
    }

    return linkedMappings;
  };

  /**
   * Validates the units to be updated.
   * @return {boolean} isValid
   */
  const validate = () => {
    let isValid = true;
    const existingECPMS = lines.map((item) => {
      return String(item.cpmMicros / 1000000);
    });
    const newECPMS = Object.values(updateLines).map((item) => {
      return item.ecpm;
    });

    for (const item of newECPMS) {
      if (existingECPMS.includes(item)) {
        alert(`${item} eCPM already exists!`);
        isValid = false;
        break;
      }
    }

    if (API.hasDuplicates(newECPMS)) {
      alert("Change duplicate eCPMs!");
      isValid = false;
    }

    return isValid;
  };

  /**
   * Sets the update operations into the mappings elements.
   * @param {Array<Object>} mappings
   */
  const setBatchUpdates = (mappings) => {
    const { account, selectedApp } = props;

    for (const item of mappings) {
      const waterfallUnits = item.foundMappings.map((x) => {
        return Object.values(x.adUnitConfigurations)[0];
      });

      item.waterfallUnits = waterfallUnits;
      item.batchUpdate = [];

      for (let i = 0; i < waterfallUnits.length; i++) {
        const batchUpdateName = `${account}/adMobNetworkWaterfallAdUnits/${
          waterfallUnits[i].split("/")[1]
        }`;
        const appDisplayName = selectedApp.manualAppInfo.displayName;
        let unitDisplayName = `${appDisplayName} ${item.ecpm}`;
        if (waterfallUnits.length > 1) unitDisplayName += ` ${i + 1}`;

        item.batchUpdate.push({
          batchUpdateName,
          unitDisplayName,
          ecpm: item.ecpm,
        });
      }
    }
  };

  /**
   * Returns the constructed Ad Units to be mapped with their primary Ad Units.
   * @param {Array<Object>} mappings
   * @return {Array<Object>} mapping objects
   */
  const getToMaps = (mappings) => {
    const toMap = [];
    for (const item of mappings) {
      for (const map of item.foundMappings) {
        const mappingAccount = map.name.split("/adUnitMappings")[0];

        // `${account}/adUnits/{adUnitId}`;
        toMap.push({
          parent: mappingAccount,
          adUnitMapping: {
            adUnitConfigurations: map.adUnitConfigurations,
            adapterId: map.adapterId,
            displayName: `AdMob Network Waterfall ${item.ecpm}`,
          },
        });
      }
    }

    return toMap;
  };

  /**
   * Returns the constructed line items to be patched.
   * @return {Object} Mask and patching objects
   */
  const getToPatch = () => {
    let mask = [];
    const toPatch = {};

    let creationCounter = -1;
    for (const line of Object.values(updateLines)) {
      const lineId = line.item.id;
      const ecpm = line.ecpm;

      mask.push(
        `mediationGroupLines["${lineId}"].state,mediationGroupLines["${creationCounter}"]`
      );

      toPatch[lineId] = {
        state: "REMOVED",
      };

      toPatch[String(creationCounter)] = {
        displayName: `AdMob Network Waterfall ${ecpm}`,
        adSourceId: "1215381445328257950",

        cpmMode: "MANUAL",
        state: "ENABLED",

        cpmMicros: String(Math.round(ecpm * 1000000)),
        adUnitMappings: line.item.adUnitMappings,
      };

      creationCounter -= 1;
    }

    return { mask, toPatch };
  };

  /** Updates the Ad Unit floors. */
  const confirmUpdate = async () => {
    const isValid = validate();
    if (!isValid) return;

    setLoading(true);
    const { account } = props;

    const mappings = await getMappings();

    setBatchUpdates(mappings);

    const toBatchUpdate = mappings
      .map((item) => {
        return item.batchUpdate;
      })
      .reduce((c, d) => {
        return [...c, ...d];
      });

    const floorResponse = await API.batchUpdate(account, toBatchUpdate);

    if (!floorResponse) {
      setLoading(false);
      return;
    }

    const toMap = getToMaps(mappings);

    const mapResponse = await API.batchCreateAdUnitMappings(account, toMap);

    if (!mapResponse) {
      setLoading(false);
      return;
    }

    const { mask, toPatch } = getToPatch();

    const patchResponse = await API.patchMediationGroup(
      selectedMediationGroup.name,
      mask.join(","),
      toPatch
    );

    console.log(patchResponse);

    setLoading(false);
    if (patchResponse) {
      setShowStatus(patchResponse);
    }
  };

  if (loading) {
    return <Loading type="load" />;
  }

  return (
    <View>
      <Title>{selectedApp?.manualAppInfo?.displayName}</Title>
      <p>{selectedApp?.appId}</p>
      <p>
        {selectedApp?.linkedAppInfo?.appStoreId?.includes(".")
          ? "Android"
          : "iOS"}
      </p>
      <br />
      <br />
      <br />
      <br />

      <Button onClick={() => setGroupModalShow(true)}>
        {selectedMediationGroup?.displayName ?? "Select Mediation Group"}
      </Button>

      <StatusModal
        header="Success!"
        body={`Successfully updated line items!`}
        show={showStatus}
        onHide={() => window.location.reload()}
      />

      <SelectModal
        show={groupModalShow}
        onHide={(group) => {
          if (group) {
            if (group === "none") {
              setSelectedMediationGroup(null);
            } else {
              setSelectedMediationGroup(group);
            }
          }
          setGroupModalShow(false);
        }}
        header="Select Mediation Group"
        elements={groups}
        selectedvalue="displayName"
        filter={(item) =>
          `${item.mediationGroupId} ${item.displayName} ${item.targeting.platform}`
        }
        title="Search for Mediation Group"
        option={(item) =>
          item.displayName +
          " - " +
          item.mediationGroupId +
          " - " +
          item.targeting?.platform
        }
      />

      {lines.map((item, index) => (
        <LineView key={index}>
          <Border />
          <UnitId>{item.displayName}</UnitId>

          <VL />
          <FieldInput
            type="number"
            onWheel={(e) => {
              e.target.blur();

              e.stopPropagation();

              setTimeout(() => {
                e.target.focus();
              }, 0);
            }}
            placeholder={"eCPM Floor: " + item.cpmMicros / 1000000}
            onChange={(e) => {
              setUpdates(item, "ecpm", e.target.value);
            }}
          />

          <Border />
        </LineView>
      ))}

      <GeneralButton
        title="Update Unit"
        action={() => {
          confirmUpdate();
        }}
      />
    </View>
  );
}
export default Update;

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const Title = styled.h2`
  font-family: sans-serif;
  margin-top: 16px;
`;

const VL = styled.div`
  height: 100%;
  width: 1px;
  background-color: #dadce0;
`;

const Border = styled.div`
  width: 3px;
  height: 100%;
`;

const LineView = styled.div`
  width: 100%;
  height: 100px;
  background-color: white;
  margin: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
`;

const FieldInput = styled.input`
  font-family: sans-serif;
  height: 100%;
  padding: 16px;
  outline: none;
  border-style: none;
  flex-grow: 100;
`;

const UnitId = styled.div`
  font-family: sans-serif;
  height: 100%;
  padding: 16px;
  outline: none;
  border-style: none;
  flex-grow: 100;
  text-align: center;
  //   background-color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  cursor: pointer;
  width: 80%;
  min-height: 50px;
  max-height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  outline: none;
`;
