/**
 * @fileoverview This file encapsulates the SelectModal function and methods used
 * by the underlying library.
 */

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SearchField } from "./SearchField";

/** Function holding the SelectModal Component. */
export const SelectModal = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);

  /** Hides the modal and sends the selected element to parent. */
  const save = () => {
    if (Object.keys(selectedItem).length > 2) {
      props.onHide(selectedItem);
    } else {
      props.onHide("none");
    }
  };

  /**
   * Received the selected element from child.
   * @param {string} id
   */
  const getSelectedItem = (id, close) => {
    if (close) {
      save();
    } else {
      setSelectedItem(id);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontFamily: "sans-serif" }}
        >
          {props.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchField
          getSelected={getSelectedItem}
          elements={props.elements}
          selectedvalue={props.selectedvalue}
          filter={(item) => props.filter(item)}
          title={props.title}
          option={(item) => props.option(item)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={save}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};
