/**
 * @fileoverview This file encapsulates the Home function and methods used
 * by the underlying library.
 */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import API from "../Util/Api";
// import { useNavigate } from "react-router-dom";
import GeneralButton from "../components/GeneralButton";
import Loading from "../components/Loading";
import Create from "./Create";
import { SelectModal } from "../components/SelectModal";
import Update from "./Update";
import Increase from "./Increase";

/** Function holding the Home Component. */
function Home() {
  // const navigate = useNavigate();
  const [pubs, setPubs] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAppsModal, setShowAppsModal] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showIncrease, setShowIncrease] = useState(false);

  const actionItems = [
    { title: "Create Ad Units" },
    { title: "Update Ad Units" },
    { title: "Increase Ad Units' eCPM" },
  ];

  /** Lists the publisher's accounts. */
  useEffect(() => {
    const listPubs = async () => {
      const response = await API.listPubs();
      if (response && response.length > 0) {
        setPubs(response);
        setAccount(response[0].name);
        await fetchApps(response[0].name);
      }
      setLoading(false);
    };
    listPubs();
  }, []);

  /**
   * Fetches the publisher's apps.
   * @param {string} account
   */
  const fetchApps = async (account) => {
    const currentApps = await API.listApps(account);

    if (currentApps) {
      const filtered = currentApps.filter((item) => {
        return item.linkedAppInfo;
      });
      filtered.sort((a, b) => a.manualAppInfo.displayName.localeCompare(b.manualAppInfo.displayName));
      console.log("filtered", filtered);
      setApps(filtered);
    }
  };

  /**
   * Fetches the selected app from child.
   * @param {Object} app
   */
  function getSelectedApp(app) {
    setSelectedApp(app);
  }

  if (loading) {
    return (
      <div className="container">
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading height="100vh" width="20%" type="load" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      <AccountsView>
        <PubContainer>
          <h3>Select Publisher:</h3>

          <Dropdown
            id="pubSelector"
            onChange={async (e) => {
              setAccount(e.target.value);
              await fetchApps(e.target.value);
            }}
          >
            {pubs.map((item, index) => (
              <option value={item.name} key={index}>
                {"ca-app-" + item.publisherId}
              </option>
            ))}
          </Dropdown>
        </PubContainer>
        <br />
        <PubContainer>
          <h3>Select App:</h3>

          <AppsButton onClick={() => setShowAppsModal(true)}>
            {selectedApp?.appId ?? "Select your app"}
          </AppsButton>

          <SelectModal
            show={showAppsModal}
            header="Select your app"
            elements={apps}
            selectedvalue="appId"
            getSelectedItem={getSelectedApp}
            onHide={(selectedItem) => {
              if (selectedItem) {
                if (selectedItem === "none") {
                  setSelectedApp(null);
                } else {
                  setSelectedApp(selectedItem);
                }
                setShowCreate(false);
              }
              setShowAppsModal(false);
            }}
            filter={(item) => {
              return `${item.manualAppInfo.displayName} ${item.linkedAppInfo.appStoreId} ${item.appId}`;
            }}
            title="Type your app name"
            option={(item) =>
              item.manualAppInfo.displayName +
              " - " +
              item.linkedAppInfo.appStoreId
            }
          />
        </PubContainer>
        <br />

        {apps.find((item) => item.appId === selectedApp?.appId) && (
          <PubContainer>
            <h3>Select Action:</h3>
            <ActionsView>
              {actionItems.map((item, index) => (
                <GeneralButton
                  key={index}
                  title={item.title}
                  action={() => {
                    switch (index) {
                      case 0:
                        setShowCreate(true);
                        setShowUpdate(false);
                        setShowIncrease(false);
                        break;
                      case 1:
                        setShowCreate(false);
                        setShowUpdate(true);
                        setShowIncrease(false);
                        break;
                      case 2:
                        setShowCreate(false);
                        setShowUpdate(false);
                        setShowIncrease(true);
                        break;
                      default:
                        throw new Error("Unsupported action!");
                    }
                  }}
                />
              ))}
            </ActionsView>
          </PubContainer>
        )}
      </AccountsView>

      <PageView>
        {showCreate ? (
          <Create selectedApp={selectedApp} account={account} />
        ) : showUpdate ? (
          <Update selectedApp={selectedApp} account={account} />
        ) : showIncrease ? (
          <Increase selectedApp={selectedApp} account={account} />
        ) : (
          <h2>No action selected!</h2>
        )}
      </PageView>
    </Row>
  );
}

export default Home;

const AccountsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  width: 40%;
  max-width: 40%;
  // background-color: green;
  height: 100%;
`;

const Dropdown = styled.select`
  width: 100%;
  height: 50px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 0px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  outline: none;
  text-align: center;
`;

const AppsButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  outline: none;
`;

const ActionsView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  // background-color: blue;
  min-height: 80vh;
`;

const PageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  flex-grow: 100;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: 80vh;
  max-height: 80vh;
  overflow: scroll;
`;

const PubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 5px;
`;
