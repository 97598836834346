/**
 * @fileoverview This file encapsulates the Auth class and methods used
 * by the underlying library.
 */

import { gapi, loadAuth2 } from "gapi-script";

/** Class holding the AdMob Authentication endpoints. */
class Auth {
  clientId = "171996105153-rekksccq2i91qgpr3pl7h566ccdscu5o.apps.googleusercontent.com";
  scopes =
    "https://www.googleapis.com/auth/admob.monetization https://www.googleapis.com/auth/admob.readonly";

  /** Initialise the gapi sdk. */
  async initialise() {
    this.auth2 = await loadAuth2(gapi, this.clientId, this.scopes);
  }

  /**
   * Attache the authentication click handler to a HTML element.
   * @param {string} element
   * @param {*} completionHandler
   */
  attachSignin(element, completionHandler) {
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        completionHandler(googleUser);
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  }

  /**
   * Returns the status of the user's authentication.
   * @return {boolean} isSignedIn
   */
  isSignedIn() {
    return this.auth2.isSignedIn.get();
  }

  /**
   * Returns the username of the authenticated user.
   * @param {*} currentUser
   * @return {string} username
   */
  getUsername(currentUser) {
    if (!currentUser) {
      currentUser = this.auth2.currentUser.get();
    }
    return currentUser.getBasicProfile().getName();
  }

  /**
   * Logs the user out of the session.
   * @param {*} completionHandler
   */
  signout(completionHandler) {
    const authInstance = gapi.auth2.getAuthInstance();
    authInstance.signOut().then(() => {
      completionHandler();
    });
  }
}

export default Auth;
