/**
 * @fileoverview This file encapsulates the Loading function and methods used
 * by the underlying library.
 */

import React from "react";
import Lottie from "lottie-react";
import animation from "../assets/google-animation.json";
import styled from "styled-components";

/** Function holding the Loading Screen Component. */
const Loading = (props) => {
  switch (props.type) {
    case "size":
      return (
        <View
          style={{
            minHeight: "100vh",
          }}
        >
          <h1>The screen size is too small!</h1>
        </View>
      );

    default:
      return (
        <View
          style={{
            [props.height ? "maxHeight" : "minHeight"]: props.height
              ? props.height
              : "100vh",
          }}
        >
          <Lottie
            style={{ width: props.width ? props.width : "20%" }}
            animationData={animation}
            loop={true}
          />
        </View>
      );
  }
};

export default Loading;

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
