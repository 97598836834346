/**
 * @fileoverview This file encapsulates the Create function and methods used
 * by the underlying library.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AdUnitField } from "../components/AdUnitField";
import GeneralButton from "../components/GeneralButton";
import API from "../Util/Api";
import Loading from "../components/Loading";
import { StatusModal } from "../components/StatusModal";

/** Function holding the Create Component. */
function Create(props) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [unitCounter, setUnitCounter] = useState(1);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState({});
  const [groups, setGroups] = useState([]);
  const [showStatus, setShowStatus] = useState(false);

  const navigate = useNavigate();

  /** Lists the medation groups on page load. */
  useEffect(() => {
    if (!props) {
      navigate("/");
      return;
    }

    const { account, selectedApp } = props;
    setSelectedApp(selectedApp);

    const listGroups = async () => {
      try {
        const res = await API.listMediationGroups(account, selectedApp.appId);
        if (res) {
          setGroups(res);
        } else {
          setGroups([]);
        }
      } catch {
        navigate("/");
      }
      setLoading(false);
    };

    listGroups();
  }, [props, navigate]);

  /**
   * Updates the ad units counter.
   * @param {string} mode
   */
  const updateCounter = (mode) => {
    if (mode === "add") {
      setUnitCounter(unitCounter + 1);
    } else {
      if (unitCounter > 1) {
        setUnitCounter(unitCounter - 1);
        const keys = Object.keys(units);
        if (keys.length - 1 > 0) {
          const obj = units;
          delete obj[keys[keys.length - 1]];
          setUnits(obj);
        }
      }
    }
  };

  /**
   * Adds a new ad unit.
   * @param {Object} unit
   */
  const addUnit = (unit) => {
    units[unit.index] = unit;
    delete units[unit.index].index;
    setUnits(units);
  };

  /** Manages the creation, mapping, and patching of ad units. */
  const create = async () => {
    const isValid = validate();
    if (!isValid) return;
    setLoading(true);
    const createdUnits = await createUnits();

    if (createdUnits) {
      const mappedUnits = await mapUnits(createdUnits);
      const mappedKeys = Object.keys(mappedUnits);

      if (mappedKeys.length > 0) {
        const mappedValues = Object.values(mappedUnits);

        const patchedUnits = await patchUnits(mappedValues);

        setLoading(false);
        setShowStatus(patchedUnits.length);
      }
    }
  };

  /**
   * Creates the waterfall ad units.
   * @return {Array<Object>}
   */
  const createUnits = async () => {
    const values = Object.values(units);
    const account = props.account;
    const creations = [];

    values.forEach((value, row) => {
      const primaryAdUnits = value.primaryAdUnits;

      primaryAdUnits.forEach((primary, index) => {
        const data = { ...value };

        data.primaryAdUnitId = primary;
        data.row = row;
        data.ecpm = Math.round(
          data.cpmFloorSettings.globalFloorMicros * 1000000
        );
        delete data.primaryAdUnits;

        if (value.primaryAdUnits.length > 1) {
          data.displayName += ` ${index + 1}`;
        }
        creations.push(data);
      });
    });

    return await API.batchCreate(account, creations);
  };

  /**
   * Maps the created ad units.
   * @param {Array<Object>} createdUnits
   * @return {Object}
   */
  const mapUnits = async (createdUnits) => {
    const account = props.account;
    const storeId = selectedApp.linkedAppInfo?.appStoreId;
    const platform = storeId.includes(".") ? "Android" : "iOS";
    const mappedUnits = {};
    const toMap = [];

    for (const item of createdUnits) {
      const { primaryAdUnitId, row } = item.mediationGroup;
      const mappingAccount = `${account}/adUnits/${
        primaryAdUnitId.split("/")[1]
      }`;

      const { adapterId, configuration } =
        API.getMappingConfigurationAndAdapter(item.format, platform);

      toMap.push({
        parent: mappingAccount,
        adUnitMapping: {
          adUnitConfigurations: {
            [configuration]: item.admobNetworkWaterfallAdUnitId,
          },
          adapterId,
          displayName: `AdMob Network Waterfall ${
            parseInt(item.cpmFloorSettings.globalFloorMicros) / 1000000
          }`,
        },
      });

      if (mappedUnits[row]) {
        mappedUnits[row].push(item);
      } else {
        mappedUnits[row] = [item];
      }
    }

    const mapping = await API.batchCreateAdUnitMappings(account, toMap);

    for (const item of createdUnits) {
      for (const map of mapping.adUnitMappings) {
        if (
          item.admobNetworkWaterfallAdUnitId ===
          Object.values(map.adUnitConfigurations)[0]
        ) {
          item.mapping = map.name;
        }
      }
    }

    if (!mapping) return;

    return mappedUnits;
  };

  /**
   * Patches the created ad units.
   * @param {Array<Object>} mappedUnits
   * @return
   */
  const patchUnits = async (mappedUnits) => {
    const account = props.account;
    const patches = [];

    for (const item of mappedUnits) {
      const { mediationGroupId, ecpm } = item[0].mediationGroup;
      const patchAccount = account + "/mediationGroups/" + mediationGroupId;
      const primaryMappings = {};

      item.forEach((element) => {
        primaryMappings[element.mediationGroup.primaryAdUnitId] =
          element.mapping;
      });

      const mask = `mediationGroupLines["-1"]`;

      const mediationGroupLines = {
        "-1": {
          displayName: `AdMob Network Waterfall ${ecpm}`,
          adSourceId: "1215381445328257950",

          cpmMode: "MANUAL",
          state: "ENABLED",

          cpmMicros: String(Math.round(ecpm * 1000000)),
          adUnitMappings: primaryMappings,
        },
      };

      const patch = await API.patchMediationGroup(
        patchAccount,
        mask,
        mediationGroupLines
      );

      if (!patch) return;
      patches.push(patch);
    }
    return patches;
  };

  /**
   * Validates the units to be created.
   * @return {boolean} isValid
   */
  const validate = () => {
    const values = Object.values(units);
    let isValid = true;

    if (values.length < 1 || unitCounter !== values.length) {
      alert("Row Missing Data!");
      return false;
    }

    const floorVerify = {};

    values.forEach((item) => {
      if (item.mediationGroup) {
        const arr = floorVerify[item.mediationGroup];
        if (arr) {
          if (item.cpmFloorSettings?.globalFloorMicros) {
            floorVerify[item.mediationGroup] = [
              ...arr,
              item.cpmFloorSettings.globalFloorMicros,
            ];
          }
        } else {
          if (item.cpmFloorSettings?.globalFloorMicros) {
            floorVerify[item.mediationGroup] = [
              item.cpmFloorSettings.globalFloorMicros,
            ];
          }
        }
      }
    });

    const keys = Object.keys(floorVerify);

    keys.forEach((key) => {
      const arr = floorVerify[key];

      if (API.hasDuplicates(arr)) {
        isValid = false;
        alert(
          "You cannot set the same floor to multiple lines of the same mediation group!"
        );
        return;
      }
    });

    values.forEach((value) => {
      if (value.warning) {
        alert("Fix your eCPM floor values!");
        isValid = false;
        return;
      }

      if (!value.mediationGroup) {
        alert("Missing Mediation Group!");
        isValid = false;
        return;
      }

      if (!value.format) {
        alert("Missing Format!");
        isValid = false;
        return;
      }

      if (!value.appId) {
        alert("Missing Add Id!");
        isValid = false;
        return;
      }

      if (!value.cpmFloorSettings) {
        alert("Missing Floor!");
        isValid = false;
        return;
      }

      if (!value.displayName) {
        alert("Missing Name!");
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  if (loading) {
    return (
      <div className="container">
        <View style={{ height: "100%" }}>
          <Loading height="100%" width="20%" type="load" />
        </View>
      </div>
    );
  }

  return (
    <View>
      <Title>{selectedApp?.manualAppInfo?.displayName}</Title>
      <p>{selectedApp?.appId}</p>

      <br />
      <br />
      <br />
      <br />

      <StatusModal
        header="Success!"
        body={`Successfully created line items!`}
        show={showStatus}
        onHide={() => window.location.reload()}
      />

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Plus onClick={() => updateCounter("add")}>+</Plus>
        <Plus onClick={() => updateCounter("subtract")}>-</Plus>
      </div>
      {new Array(unitCounter).fill(1).map((_, index) => (
        <AdUnitField
          account={props.account}
          addUnit={addUnit}
          index={index}
          appId={selectedApp?.appId}
          appName={selectedApp?.manualAppInfo?.displayName}
          key={index}
          groups={groups}
        />
      ))}
      <GeneralButton
        title="Create Line Items"
        action={() => {
          create();
        }}
      />
    </View>
  );
}
export default Create;

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 16px;
`;

const Plus = styled.button`
  width: 300px;
  height: 50px;
  border-radius: 5px;
  border-style: none;
  font-size: 20px;
  margin: 16px;
  background-color: #1a73e8;
  color: white;
  text-align: center;
`;

const Title = styled.h2`
  font-family: sans-serif;
  margin-top: 16px;
`;
