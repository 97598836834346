/**
 * @fileoverview This file encapsulates the AdUnitField function and methods used
 * by the underlying library.
 */

import React, { useState } from "react";
import styled from "styled-components";
import { SelectModal } from "./SelectModal";

/** Function holding the AdUnit Field Component. */
export const AdUnitField = (props) => {
  const [format, setFormat] = useState("Format");
  const [floor, setFloor] = useState("");
  const [warningText, setWarningText] = useState("");
  const [unitName, setUnitName] = useState("Unit Name");
  const [unitData, setUnitData] = useState({});
  const [groupModalShow, setGroupModalShow] = useState(false);
  const [selectedMediationGroup, setSelectedMediationGroup] = useState(false);

  /**
   * Updates the selected mediation group.
   * @param {Object} group
   */
  function updateSelectedMediationGroup(group) {
    setSelectedMediationGroup(group);
    if (!group) {
      setData("primaryAdUnits", null);
      setData("format", null);
      setFormat("Format");
      setData("floor", null);
      setFloor("");
      setData("mediationGroup", null);
    } else {
      setData("primaryAdUnits", group.targeting.adUnitIds);
      setData("mediationGroup", group.mediationGroupId);

      setData("format", group.targeting.format);
      setFormat(group.targeting.format);
    }
  }

  /**
   * Sets the data state.
   * @param {string} key
   * @param {*} value
   */
  const setData = (key, value) => {
    const data = unitData;
    data[key] = value;
    setUnitData(data);
    props.addUnit(construct(data));
  };

  /**
   * Checks if the eCPM floor already exists on AdMob
   * @param {string} currFloor
   * @return {boolean} doesFloorExist
   */
  const checkIfFloorExists = (currFloor) => {
    let alreadyExists = false;
    const curr = props.groups.find(
      (item) =>
        item.mediationGroupId === selectedMediationGroup.mediationGroupId
    );

    const lines = Object.values(curr.mediationGroupLines);

    lines.forEach((item) => {
      const lineCPM = String(item.cpmMicros / 1000000);

      if (item.adSourceId === "1215381445328257950" && lineCPM === currFloor) {
        alreadyExists = true;
      }
    });

    setWarningText(
      alreadyExists ? "Floor already used! Please change it!" : ""
    );

    return alreadyExists;
  };

  /**
   * Retuns the constructed unit object.
   * @param {Object} data
   * @return {Object}
   */
  const construct = (data) => {
    const constructed = {
      appId: props.appId,
      index: props.index,
    };
    if (data.primaryAdUnits) {
      constructed.primaryAdUnits = data.primaryAdUnits;
    }
    if (data.mediationGroup) constructed.mediationGroup = data.mediationGroup;
    if (data.format) constructed.format = data.format;
    if (data.name) constructed.displayName = data.name;
    if (data.floor) {
      constructed.cpmFloorSettings = {
        globalFloorMicros: data.floor,
      };

      if (checkIfFloorExists(data.floor)) {
        constructed.warning = true;
      }
    }

    return constructed;
  };

  return (
    <View>
      <Warning>{warningText}</Warning>
      <AdUnitBox>
        <Border />

        <Button
          onClick={() => {
            setGroupModalShow(true);
          }}
        >
          {selectedMediationGroup?.displayName ?? "Select Mediation Group"}
        </Button>

        <VL />

        <SelectModal
          show={groupModalShow}
          onHide={(group) => {
            if (group) {
              if (group === "none") {
                updateSelectedMediationGroup(null);
              } else {
                updateSelectedMediationGroup(group);
              }
            }
            setGroupModalShow(false);
          }}
          header="Select Mediation Group"
          elements={props.groups}
          selectedvalue="displayName"
          filter={(item) =>
            `${item.mediationGroupId} ${item.displayName} ${item.targeting.platform}`
          }
          title="Search for Mediation Group"
          option={(item) =>
            item.displayName +
            " - " +
            item.mediationGroupId +
            " - " +
            item.targeting?.platform
          }
        />

        <VL />
        <TextField disabled value={format} />
        <VL />
        <TextField disabled value={unitName} />
        <VL />
        <FloorField
          style={{ flexGrow: 100 }}
          disabled={!selectedMediationGroup}
          value={floor}
          onChange={(e) => {
            setFloor(e.target.value);
            setData("floor", e.target.value);
            checkIfFloorExists(e.target.value);

            const name = `${props.appName} ${format.toLowerCase()} ${
              e.target.value
            }`;
            setUnitName(name);
            setData("name", name);
          }}
          onWheel={(e) => {
            e.target.blur();

            e.stopPropagation();

            setTimeout(() => {
              e.target.focus();
            }, 0);
          }}
          type="number"
          placeholder="eCPM Floor"
        />
        <Border />
      </AdUnitBox>
    </View>
  );
};

const VL = styled.div`
  height: 100%;
  width: 1px;
  background-color: #dadce0;
`;

const AdUnitBox = styled.div`
  min-width: 80%;
  height: 80px;
  border: 1px solid #dadce0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TextField = styled.input`
  padding: 16px;
  height: 100%;
  min-width: 200px;
  border-style: none;
  outline: none;
  font-family: sans-serif;
  flex-grow: 100;
`;

const FloorField = styled.input`
  padding: 16px;
  height: 100%;
  border-style: none;
  outline: none;
`;

const Button = styled.button`
  height: 100%;
  min-width: 200px;
  border-style: none;
  background-color: white;
  font-family: sans-serif;
`;

const Border = styled.div`
  width: 3px;
  height: 100%;
`;

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px;
`;

const Warning = styled.h2`
  color: red;
  font-size: 16px;
`;
