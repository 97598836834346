/**
 * @fileoverview This file encapsulates the App function and methods used
 * by the underlying library.
 */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Create from "./Create";
import { UserCard } from "../components/UserCard";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Loading from "../components/Loading";
import Update from "./Update";
import GeneralButton from "../components/GeneralButton";
import Auth from "../Util/Auth";

/** Function holding the App Component. */
export default function App() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [auth] = useState(new Auth());

  /** Handles the window resize. */
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  /** Initialises the app's authentication. */
  useEffect(() => {
    const init = async () => {
      await auth.initialise();

      if (auth.isSignedIn()) {
        setUsername(auth.getUsername());
      }

      setLoading(false);
    };
    init();
  }, [auth]);

  /** Authenticates the user onLoginTapped. */
  function onLoginTapped() {
    if (auth.isSignedIn()) {
      setUsername(auth.getUsername());
    } else {
      const element = document.getElementById("authView");
      auth.attachSignin(element, (googleUser) => {
        setUsername(auth.getUsername(googleUser));
      });

      document.getElementById("authView").click();
    }
  }

  /** Logs the user out of the session. */
  const signOut = () => {
    auth.signout(() => {
      setUsername(null);
      console.log("User signed out.");
    });
  };

  if (loading) {
    return <Loading type="load" />;
  }

  if (windowWidth <= 1000) {
    return <Loading type="size" />;
  }

  if (username) {
    return (
      <Router>
        <div style={{ background: "white" }}>
          <Navbar expand="lg" style={{ height: 60 }}>
            <Container fluid>
              <Navbar.Brand href="/">
                <UserCard username={username} />
              </Navbar.Brand>
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 16,
                }}
                navbarScroll
              >
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link onClick={() => signOut()}>Logout</Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <Separator />
        </div>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/create" element={<Create />}></Route>
          <Route exact path="/update" element={<Update />}></Route>
        </Routes>
      </Router>
    );
  } else {
    return (
      <div className="container">
        <View>
          <img width="50" src={require("../assets/logo.png")} alt="logo" />
          <h1 style={{ fontFamily: "sans-serif", margin: "16px 0px 64px 0px" }}>
            Dolphin Dashboard
          </h1>
          <GeneralButton title="Login" action={onLoginTapped} />
          <div id="authView"></div>
        </View>
      </div>
    );
  }
}

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  height: 100vh;
`;

const Separator = styled.div`
  border-bottom: 1px solid #dadce0;
  background-color: #fff;
  color: #757575;
  justify-content: space-between;
  // padding: 8px 20px 8px 12px;
`;
