/**
 * @fileoverview This file encapsulates the UserCard function and methods used
 * by the underlying library.
 */

import React from "react";
import styled from "styled-components";

/** Function holding the UserCard Component. */
export const UserCard = (props) => {
  return (
    <View>
      <Image
        src={require("../assets/logo.png")}
        alt="user profile"
        referrerpolicy="no-referrer"
      />
      <Text>Welcome {props.username}!</Text>
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-left: 16px;
`;

const Image = styled.img`
  width: 30px;
  height: 30px;
  padding: 0;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-left: 16px;
  font-family: sans-serif;
  color: #545e75;
`;
