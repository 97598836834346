/**
 * @fileoverview This file encapsulates the StatusModal function and methods used
 * by the underlying library.
 */

import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/** Function holding the StatusModal Component. */
export const StatusModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontFamily: "sans-serif" }}
        >
          {props.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
