/**
 * @fileoverview This file encapsulates the SearchField function and methods used
 * by the underlying library.
 */

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

/** Function holding the SearchField Component. */
export const SearchField = (props) => {
  const [fetchedElements, setFetchedElements] = useState([]);
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});

  const inputId = String(getRandomInt(100000));
  const browsersId = String(getRandomInt(100000));

  let currentFocus = useRef(-1);

  /** Updates the selectedElement in the parent class. */
  useEffect(() => {
    props.getSelected(selectedElement);
  }, [selectedElement, props]);

  /** Updates the fetched elements on props.elements updated.  */
  useEffect(() => {
    setFetchedElements(props.elements);
    setElements(props.elements);
  }, [props.elements]);

  /** Sets the input focus on elements updated */
  useEffect(() => {
    const input = document.getElementById(inputId);
    const browsers = document.getElementById(browsersId);
    setInputFocus(input, browsers);
  }, [elements, browsersId, inputId]);

  /**
   * Sets the input focus.
   * @param {*} input
   * @param {*} browsers
   */
  const setInputFocus = (input, browsers) => {
    input.onfocus = function () {
      browsers.style.display = "block";
      input.style.borderRadius = "5px 5px 0 0";
    };
    for (let option of browsers.options) {
      option.onclick = function (e) {
        input.value = option.value;
        browsers.style.display = "none";
        input.style.borderRadius = "5px";

        const selected = e.target.getAttribute("data-selected-element");
        setSelectedElement(JSON.parse(selected));
      };
    }

    input.onkeydown = function (e) {
      if (e.key === "ArrowDown") {
        const val = currentFocus.current + 1;
        currentFocus.current = val;

        addActive(browsers.options);
      } else if (e.key === "ArrowUp") {
        currentFocus.current = currentFocus.current - 1;
        addActive(browsers.options);
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (currentFocus.current > -1) {
          if (browsers.options) browsers.options[currentFocus.current].click();
        }
      }
    };

    function addActive(x) {
      if (!x) return false;
      removeActive(x);
      if (currentFocus.current >= x.length) currentFocus.current = 0;
      if (currentFocus.current < 0) currentFocus.current = x.length - 1;

      x[currentFocus.current].classList.add("active");
    }
    function removeActive(x) {
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("active");
      }
    }
  };

  /**
   * Called when input is changed.
   * @param {*} element
   */
  const inputOnChanged = (element) => {
    const browsers = document.getElementById(browsersId);

    const input = element.target;

    setSelectedElement({
      [props.selectedvalue]: input.value,
    });
    currentFocus.current = -1;
    var text = input.value.toUpperCase();

    const filtered = fetchedElements.filter((item) => {
      const filter = props.filter(item);
      if (filter) {
        return filter.toUpperCase().indexOf(text) > -1;
      } else {
        return false;
      }
    });

    setElements(filtered);

    if (filtered.length > 0) {
      browsers.style.display = "block";
    }
  };

  return (
    <View>
      <TextField
        autocomplete="off"
        role="combobox"
        list=""
        id={inputId}
        name={browsersId}
        placeholder={props.title}
        value={selectedElement[props.selectedvalue] ?? ""}
        onChange={(e) => inputOnChanged(e)}
      />

      <Lister id={browsersId} role="listbox">
        {elements.map((item, index) => (
          <SearchOption
            key={index}
            value={item[props.selectedvalue]}
            data-selected-element={JSON.stringify(item)}
          >
            {props.option(item)}
          </SearchOption>
        ))}
      </Lister>
    </View>
  );
};

const View = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const SearchOption = styled.option`
  background-color: white;
  padding: 4px;
  color: black;
  margin-bottom: 1px;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  curser: pointer;
  font-samily: sans-serif;
  &&.active {
    background-color: #add8e6;
  }

  &&:hover {
    background-color: #add8e6;
  }
`;

const Lister = styled.datalist`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 100%;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto;
`;

const TextField = styled.input`
  width: 100%;
  height: 50px;
  font-size: 16px;
  border-radius: 5px;
  padding: 16px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  outline: none;
  font-samily: sans-serif;
`;

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
