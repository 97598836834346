/**
 * @fileoverview This file encapsulates the Increase function and methods used
 * by the underlying library.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import GeneralButton from "../components/GeneralButton";
import API from "../Util/Api";
import Loading from "../components/Loading";
import { SelectModal } from "../components/SelectModal";
import { StatusModal } from "../components/StatusModal";

/** Function holding the Increase Component. */
function Increase(props) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedMediationGroup, setSelectedMediationGroup] = useState(null);
  const [updateLines, setUpdateLines] = useState({});
  const [lines, setLines] = useState([]);
  const [groupModalShow, setGroupModalShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showStatus, setShowStatus] = useState(false);

  const navigate = useNavigate();

  /** Lists the medation groups on page load. */
  useEffect(() => {
    if (!props) {
      navigate("/");
      return;
    }

    const { account, selectedApp } = props;
    setSelectedApp(selectedApp);

    const listGroups = async () => {
      try {
        const res = await API.listMediationGroups(account, selectedApp.appId);
        if (res) {
          setGroups(res);
        } else {
          setGroups([]);
        }
      } catch {
        navigate("/");
      }
      setLoading(false);
    };

    listGroups();
  }, [props, navigate]);

  /** Sets the mediation group lines after a mediation group is selected. */
  useEffect(() => {
    if (
      selectedMediationGroup &&
      Object.keys(selectedMediationGroup).length > 2
    ) {
      // internal helper method
      const setUpdates = (item, field, value) => {
        //console.log("updating", item, "field", field, "to" , value);
        const toSet = updateLines;
        if (toSet[item.id]) {
          if (value !== "") {
            toSet[item.id][field] = value;
          } else {
            delete toSet[item.id][field];
            if (Object.keys(toSet[item.id]).length === 1) {
              delete toSet[item.id];
            }
          }
        } else {
          if (value !== "") {
            toSet[item.id] = { [field]: value, item };
          }
        }
        setUpdateLines(toSet);
      };

      const group = selectedMediationGroup;
      const lines = Object.values(group.mediationGroupLines)
        .filter((item) => {
          return item.adSourceId === "1215381445328257950";
        })
        .sort((a, b) => b.cpmMicros - a.cpmMicros);
      setLines(lines);
      for (const item of lines) {
        var cpmMicros = parseInt(item.cpmMicros);
        // Update only eCPM > 1 cent (>$0.01) to keep catch all lines
        if (cpmMicros > 10000) {
          setUpdates(item, "ecpm", (cpmMicros + 10000) / 1000000);
        }
        //break; // just one for now
      }
      // console.log("mediationGrouplines", mediationGrouplines);
      // console.log("lines", lines);
      // console.log("updateLines", updateLines);
    }
  }, [selectedMediationGroup, updateLines]);

  /**
   * Validates the units to be updated.
   * @return {boolean} isValid
   */
  const validate = () => {
    let isValid = true;
    const existingECPMS = lines.map((item) => {
      return String(item.cpmMicros / 1000000);
    });
    const newECPMS = Object.values(updateLines).map((item) => {
      return item.ecpm;
    });

    for (const item of newECPMS) {
      if (existingECPMS.includes(item)) {
        alert(`${item} eCPM already exists!`);
        isValid = false;
        break;
      }
    }

    if (API.hasDuplicates(newECPMS)) {
      alert("Change duplicate eCPMs!");
      isValid = false;
    }

    return isValid;
  };

  /**
   * Returns the constructed line items to be patched.
   * @return {Object} Mask and patching objects
   */
  const getToPatch = (mappedUnits) => {
    let mask = [];
    const toPatch = {};

    let creationCounter = -1;
    for (const line of Object.values(updateLines)) {
      const lineId = line.item.id;

      mask.push(
        `mediationGroupLines["${lineId}"].state`
      );

      toPatch[lineId] = {
        state: "REMOVED",
      };
    }

    for (const mappings of mappedUnits) {
      const primaryMappings = {};

      for (const element of Object.values(mappings)) {
        // console.log("mapping", element);
        var ecpm = element.cpmFloorSettings.globalFloorMicros / 1000000;
        // console.log("ecpm", ecpm);

        primaryMappings[element.mediationGroup.primaryAdUnitId] =
          element.mapping;
      }

      toPatch[String(creationCounter)] = {
        displayName: `AdMob Network Waterfall ${ecpm}`,
        adSourceId: "1215381445328257950",

        cpmMode: "MANUAL",
        state: "ENABLED",

        cpmMicros: String(Math.round(ecpm * 1000000)),
        adUnitMappings: primaryMappings,
      };

      mask.push(
        `mediationGroupLines["${creationCounter}"]`
      );

      creationCounter -= 1;
    }

    return { mask, toPatch };
  };

  /** Updates the Ad Unit floors. */
  const confirmIncrease = async () => {
    const isValid = validate();
    if (!isValid) return;

    setLoading(true);
    //const { account } = props;

    //const mappings = await getMappings();

    // console.log("Mappings ", mappings);

    const createdUnits = await createUnits();

    if (createdUnits) {
      const mappedUnits = await mapUnits(createdUnits);
      //console.log("mappedUnits", mappedUnits);
      const mappedKeys = Object.keys(mappedUnits);

      if (mappedKeys.length > 0) {
        const mappedValues = Object.values(mappedUnits);

        const { mask, toPatch } = getToPatch(mappedValues);

        const patchResponse = await API.patchMediationGroup(
          selectedMediationGroup.name,
          mask.join(","),
          toPatch
        );

        console.log(patchResponse);

        setLoading(false);
        if (patchResponse) {
          setShowStatus(patchResponse);
        }
      }
    }
  };

  /**
   * Creates the waterfall ad units.
   * @return {Array<Object>}
   */
  const createUnits = async () => {
    const values = Object.values(updateLines);
    const account = props.account;
    const creations = [];

    values.forEach((value, row) => {
      console.log("create value", value);
      const primaryAdUnits = Object.keys(value.item.adUnitMappings);

      primaryAdUnits.forEach((primary, index) => {
        const data = {};

        data.appId = selectedApp.appId;
        data.displayName = `${selectedApp.manualAppInfo.displayName} ${value.ecpm}`;
        data.ecpm = Math.round(value.ecpm * 1000000);
        data.format = selectedMediationGroup.targeting.format;
        data.mediationGroup = selectedMediationGroup.mediationGroupId;
        data.primaryAdUnitId = primary;
        data.row = row;
        data.cpmFloorSettings = {
          //globalFloorMicros: value.ecpm * 1000000
          globalFloorMicros: String(value.ecpm)
        };

        if (primaryAdUnits.length > 1) {
          data.displayName += ` ${index + 1}`;
        }
        creations.push(data);
      });
    });

    console.log("creations", creations);
    //return null;
    return await API.batchCreate(account, creations);
  };

  /**
   * Maps the created ad units.
   * @param {Array<Object>} createdUnits
   * @return {Object}
   */
  const mapUnits = async (createdUnits) => {
    const account = props.account;
    const storeId = selectedApp.linkedAppInfo?.appStoreId;
    const platform = storeId.includes(".") ? "Android" : "iOS";
    const mappedUnits = {};
    const toMap = [];

    for (const item of createdUnits) {
      const { primaryAdUnitId, row } = item.mediationGroup;
      const mappingAccount = `${account}/adUnits/${
        primaryAdUnitId.split("/")[1]
      }`;

      const { adapterId, configuration } =
        API.getMappingConfigurationAndAdapter(item.format, platform);

      toMap.push({
        parent: mappingAccount,
        adUnitMapping: {
          adUnitConfigurations: {
            [configuration]: item.admobNetworkWaterfallAdUnitId,
          },
          adapterId,
          displayName: `AdMob Network Waterfall ${
            parseInt(item.cpmFloorSettings.globalFloorMicros) / 1000000
          }`,
        },
      });

      if (mappedUnits[row]) {
        mappedUnits[row].push(item);
      } else {
        mappedUnits[row] = [item];
      }
    }

    const mapping = await API.batchCreateAdUnitMappings(account, toMap);

    for (const item of createdUnits) {
      for (const map of mapping.adUnitMappings) {
        if (
          item.admobNetworkWaterfallAdUnitId ===
          Object.values(map.adUnitConfigurations)[0]
        ) {
          item.mapping = map.name;
        }
      }
    }

    if (!mapping) return;

    return mappedUnits;
  };

  if (loading) {
    return <Loading type="load" />;
  }

  return (
    <View>
      <Title>{selectedApp?.manualAppInfo?.displayName}</Title>
      <p>{selectedApp?.appId}</p>
      <p>
        {selectedApp?.linkedAppInfo?.appStoreId?.includes(".")
          ? "Android"
          : "iOS"}
      </p>
      <br />
      <br />
      <br />
      <br />

      <Button onClick={() => setGroupModalShow(true)}>
        {selectedMediationGroup?.displayName ?? "Select Mediation Group"}
      </Button>

      <StatusModal
        header="Success!"
        body={`Successfully updated line items!`}
        show={showStatus}
        onHide={() => window.location.reload()}
      />

      <SelectModal
        show={groupModalShow}
        onHide={(group) => {
          if (group) {
            if (group === "none") {
              setSelectedMediationGroup(null);
            } else {
              console.log("mediation group", group);
              setSelectedMediationGroup(group);
            }
          }
          setGroupModalShow(false);
        }}
        header="Select Mediation Group"
        elements={groups}
        selectedvalue="displayName"
        filter={(item) =>
          `${item.mediationGroupId} ${item.displayName} ${item.targeting.platform}`
        }
        title="Search for Mediation Group"
        option={(item) =>
          item.displayName +
          " - " +
          item.mediationGroupId +
          " - " +
          item.targeting?.platform
        }
      />

      {lines.map((item, index) => (
        <LineView key={index}>
          <Border />
          <UnitId>{item.displayName}</UnitId>

          <VL />
          <ECMP>eCPM Floor: {item.cpmMicros / 1000000}</ECMP>
          <Border />
        </LineView>
      ))}

      {selectedMediationGroup ? (
        <GeneralButton
          title="Create new ad units with eCPMs +1ct"
          action={() => {
            confirmIncrease();
          }}
        />
        ) : ""}
    </View>
  );
}
export default Increase;

const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const Title = styled.h2`
  font-family: sans-serif;
  margin-top: 16px;
`;

const VL = styled.div`
  height: 100%;
  width: 1px;
  background-color: #dadce0;
`;

const Border = styled.div`
  width: 3px;
  height: 100%;
`;

const LineView = styled.div`
  width: 100%;
  height: 100px;
  background-color: white;
  margin: 3px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
`;

const ECMP = styled.div`
  font-family: sans-serif;
  height: 100%;
  padding: 16px;
  outline: none;
  border-style: none;
  flex-grow: 100;
`;

const UnitId = styled.div`
  font-family: sans-serif;
  height: 100%;
  padding: 16px;
  outline: none;
  border-style: none;
  flex-grow: 100;
  text-align: center;
  //   background-color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  cursor: pointer;
  width: 80%;
  min-height: 50px;
  max-height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  outline: none;
`;
