/**
 * @fileoverview This file encapsulates the API class and methods used
 * by the underlying library.
 */

import { gapi } from "gapi-script";

/** Class holding the AdMob API endpoints. */
export default class API {
  /**
   * Update the specified mediation group under the specified AdMob account.
   * @param {string} account
   * @param {string} mask
   * @param {Object} mediationGroupLines
   * @return {Promise<Object>} mediation groups
   */
  static async patchMediationGroup(account, mask, mediationGroupLines) {
    const url = `https://admob.googleapis.com/v1alpha/${account}?updateMask=${mask}`;

    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify({
        mediationGroupLines,
      }),
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("Patch Mediation Group");
          console.log(response);
          resolve(response);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * Batch create the ad unit mappings under the specific AdMob account.
   * @param {string} account
   * @param {Array<Object>} requests
   * @return {Object} created mappings
   */
  static async batchCreateAdUnitMappings(account, requests) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/adUnitMappings:batchCreate`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify({
        requests,
      }),
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("Batch Create Ad Unit Mappings");
          console.log(response);
          resolve(response);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * Returns the Ad Unit mapping adapter Id and configuration
   * @param {string} format
   * @param {string} platform
   * @return {Object} the adapter id and configuration
   */
  static getMappingConfigurationAndAdapter(format, platform) {
    let adapterId;
    let configuration;

    switch (format) {
      case "BANNER":
        adapterId = platform === "iOS" ? "505" : "504";
        configuration = platform === "iOS" ? "536" : "535";
        break;
      case "INTERSTITIAL":
        adapterId = platform === "iOS" ? "505" : "504";
        configuration = platform === "iOS" ? "536" : "535";
        break;
      case "REWARDED":
        adapterId = platform === "iOS" ? "507" : "506";
        configuration = platform === "iOS" ? "538" : "537";
        break;
      case "NATIVE":
        adapterId = platform === "iOS" ? "509" : "508";
        configuration = platform === "iOS" ? "540" : "539";
        break;
      default:
        adapterId = platform === "iOS" ? "511" : "510";
        configuration = platform === "iOS" ? "542" : "541";
        break;
    }

    return { adapterId, configuration };
  }

  /**
   * List ad unit mappings under the specified AdMob account and ad unit.
   * @param {string} account
   * @return {Array<Object>} ad unit mappings
   */
  static async listAdUnitMappings(account) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/adUnitMappings`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("listAdUnitMappingss");
          console.log(response);
          resolve(response.adUnitMappings);

          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * Batch update operation for AdMob Network Waterfall ad unit.
   * @param {string} account
   * @param {Array<Object>} units
   * @return {Promise<Array<Object>>} update responses
   */
  static async batchUpdate(account, items) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/adMobNetworkWaterfallAdUnits:batchUpdate`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify({
        requests: items.map((item) => {
          return {
            adMobNetworkWaterfallAdUnit: {
              name: item.batchUpdateName,
              displayName: item.unitDisplayName,
              cpmFloorSettings: {
                globalFloorMicros: Math.round(parseFloat(item.ecpm) * 1000000),
              },
            },
          };
        }),
        updateMask: "displayName,cpmFloorSettings",
      }),
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("Batch update waterfall Ad Units");
          console.log(response);
          resolve(response);

          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * Batch create operation for AdMob Network Waterfall ad unit.
   * @param {string} account
   * @param {Array<Object>} units
   * @return {Promise<Array<Object>>} created units
   */
  static async batchCreate(account, units) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/adMobNetworkWaterfallAdUnits:batchCreate`;

    const mediationGroups = {};

    units.forEach((item) => {
      mediationGroups[item.displayName] = {
        mediationGroupId: item.mediationGroup,
        ecpm: item.cpmFloorSettings.globalFloorMicros,
        primaryAdUnitId: item.primaryAdUnitId,
        row: item.row,
      };
    });

    const body = {
      requests: units.map((el) => {
        const temp = { ...el };
        delete temp.mediationGroup;
        delete temp.primaryAdUnitId;
        delete temp.row;

        if (
          temp.format === "REWARDED" ||
          temp.format === "REWARDED_INTERSTITIAL"
        ) {
          temp.adTypes = ["VIDEO"];
        } else {
          temp.adTypes = ["RICH_MEDIA", "VIDEO"];
        }

        temp.cpmFloorSettings.globalFloorMicros = temp.ecpm;
        delete temp.ecpm;
        return {
          adMobNetworkWaterfallAdUnit: temp,
        };
      }),
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
      body: JSON.stringify(body),
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("batch create waterfall ad units");
          console.log(response);
          resolve(
            response.adMobNetworkWaterfallAdUnits.map((item) => {
              item.mediationGroup = mediationGroups[item.displayName];
              return item;
            })
          );
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * List mediation groups under the specified AdMob account.
   * @param {string} account
   * @param {string} filter
   * @return {Promise<Array<Object>>} mediation groups
   */
  static async listMediationGroups(account, filter) {
    let url = `https://admob.googleapis.com/v1alpha/${account}/mediationGroups`;

    if (filter) {
      url += "?filter=CONTAINS_ANY%28APP_IDS%2C" + filter.split("~")[1] + "%29";
    }

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("List mediation groups");
          console.log(response);
          resolve(response.mediationGroups);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * List the ad units under the specified AdMob account.
   * @param {string} account
   * @return {Promise<Array<Object>>} ad units
   */
  static async listAdMobNetworkStandardAdUnits(account) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/adUnits`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("List Standard Ad Units");
          console.log(response);
          resolve(response.adUnits);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * List the AdMob Network Waterfall ad unit(s) under the specified AdMob account.
   * @param {string} account
   * @return {Promise<Array<Object>>} ad units
   */
  static async listAdMobNetworkWaterfallAdUnits(account) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/adMobNetworkWaterfallAdUnits`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("List Waterfall Ad Units");
          console.log(response);
          resolve(response.adMobNetworkWaterfallAdUnits);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * Lists the AdMob publisher account that was most recently signed in to from the AdMob UI.
   * @return {Promise<Array<Object>>} account
   */
  static async listPubs() {
    const url = `https://admob.googleapis.com/v1alpha/accounts`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("List Publishers");
          console.log(response);
          resolve(response.account);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * List the apps under the specified AdMob account.
   * @param {string} account
   * @return {Promise<Array<Object>>} apps
   */
  static async listApps(account) {
    const url = `https://admob.googleapis.com/v1alpha/${account}/apps`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };

    return new Promise((resolve, reject) => {
      API.doRequest(url, options)
        .then((response) => {
          if (response.error) {
            alert(response.error.message);
            console.log(response.error);
            reject(response.error);
            return;
          }
          console.log("List Apps");
          console.log(response);
          resolve(response.apps);
          return;
        })
        .catch((error) => {
          alert(error);
          console.log(error);
          reject(error);
          return;
        });
    });
  }

  /**
   * Creates HTTP request.
   * @param {string} url
   * @param {Object} options
   * @return {Promise<*>} promise
   */
  static async doRequest(url, options) {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  /**
   * Returns the user's token.
   * @return {string} access token
   */
  static getAccessToken() {
    const auth2 = gapi.auth2.getAuthInstance();
    const authResponse = auth2.currentUser.get().getAuthResponse(true);
    const accessToken = authResponse.access_token;

    return accessToken;
  }

  /**
   * Checks if an array has duplicates
   * @param {*} array
   * @return {boolean}
   */
  static hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }
}
